var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { computed, defineComponent, inject, onBeforeMount, ref, watch } from 'vue';
import ProfilePictureChangeBox from '@/components/ProfilePictureChangeBox.vue';
import moment from 'moment';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
export default defineComponent({
    components: { ProfilePictureChangeBox: ProfilePictureChangeBox },
    setup: function () {
        var _this = this;
        var authStore = inject('authState');
        var lovStore = inject('lovStore');
        var configurationStore = inject('configurationStore');
        var userData = computed(function () { return authStore.state.userData; });
        var identityDocTypes = ref([]);
        var docTypeConfig = [
            {
                countryId: 0,
                docTypeId: 0,
                placeholder: 'Ej. 12345678X',
                id: 'identityDocNumInputDNI'
            },
            {
                countryId: 0,
                docTypeId: 1,
                placeholder: 'Ej. A1234567',
                id: 'identityDocNumInputPassport'
            },
            {
                countryId: 0,
                docTypeId: 2,
                placeholder: 'Ej. X1234567X',
                id: 'identityDocNumInputNIE'
            },
            {
                countryId: 1,
                docTypeId: 0,
                placeholder: 'Ej. 12345678 9 AB1',
                id: 'identityDocNumInputNIC'
            },
            {
                countryId: 1,
                docTypeId: 1,
                placeholder: 'Ej. A1234567',
                id: 'identityDocNumInputPassport'
            }
        ];
        var isLoading = ref(false);
        var selectedIdentityDocType = computed(function () {
            if (identityDocTypes.value && identityDocTypes.value.length > 0) {
                var data = identityDocTypes.value.find(function (doc) { return doc.id === identityDocType.value; });
                return data;
            }
            return null;
        });
        var identityDocInputAttributes = computed(function () {
            var countryId = userData.value.residenceCountry.elementId;
            var docTypeId = identityDocType.value;
            // Buscar el objeto que coincide con el país y el tipo de documento
            var config = docTypeConfig.find(function (item) { return item.countryId === countryId && item.docTypeId === docTypeId; });
            // Si se encuentra una configuración, la retornamos, de lo contrario devolvemos valores por defecto
            return config || {
                placeholder: 'Introduce el número de documento',
                id: 'identityDocNumInputDefault'
            };
        });
        var validateField = useForm().validateField;
        var _a = useField('alias', yup.string().nullable().required(function () { return i18n.global.t('FIELD_REQUIRED'); })), errorAlias = _a.errorMessage, alias = _a.value;
        var _b = useField('name', yup.string().nullable().required(function () { return i18n.global.t('FIELD_REQUIRED'); })), errorName = _b.errorMessage, name = _b.value;
        var _c = useField('surname1', yup.string().nullable().required(function () { return i18n.global.t('FIELD_REQUIRED'); })), errorSurname1 = _c.errorMessage, surname1 = _c.value;
        var _d = useField('surname2', yup.string().nullable().required(function () { return i18n.global.t('FIELD_REQUIRED'); })), errorSurname2 = _d.errorMessage, surname2 = _d.value;
        var _e = useField('gender', yup.mixed().oneOf(['M', 'W']).required(function () { return i18n.global.t('FIELD_REQUIRED'); })), errorGender = _e.errorMessage, gender = _e.value;
        var _f = useField('date', yup
            .date()
            .nullable()
            .test('noEmpty', function () { return i18n.global.t('FIELD_REQUIRED'); }, function (value) {
            if (value === null) {
                return false;
            }
            return true;
        })
            .test('DOB', function () { return i18n.global.t('FORM_ERROR_MIN_ADULT'); }, function (value) {
            if (value === null) {
                return true;
            }
            return moment().diff(value, 'years') >= 16;
        })
            .test('DOB', function () { return i18n.global.t('FORM_ERROR_MAX_ADULT'); }, function (value) {
            if (value === null) {
                return true;
            }
            return moment().diff(value, 'years') <= 100;
        })
            .transform(function (value, originalValue) {
            var parsedDate = originalValue === '' ? null : new Date(originalValue);
            return parsedDate;
        })), errorDate = _f.errorMessage, date = _f.value;
        var _g = useField('identityDocType', yup.number()), errorIdentityDocType = _g.errorMessage, identityDocType = _g.value;
        var _h = useField('identityDocNum', yup
            .string()
            .nullable()
            .test('isValidIdentityDocNum', function (value) {
            if (userData.value.residenceCountry.elementId === 0) {
                // Validación para DNI o NIE en caso de que el país de residencia sea España (elementId = 0)
                if (identityDocType.value === 0) {
                    return /^[0-9]{8}[A-Za-z]$/.test(value !== null && value !== void 0 ? value : '') || this.createError({
                        message: i18n.global.t('INVALID_DNI'),
                    });
                }
                if (identityDocType.value === 2) {
                    return /^[XYZxyz][0-9]{7}[A-Za-z]$/.test(value !== null && value !== void 0 ? value : '') || this.createError({
                        message: i18n.global.t('INVALID_NIE'),
                    });
                }
            }
            else if (userData.value.residenceCountry.elementId === 1) {
                // Validación para NIC en caso de que el país de residencia sea Portugal (elementId = 1)
                if (identityDocType.value === 0) {
                    return /^[0-9]{8}\s[0-9]\s[A-Za-z]{2}[0-9]$/.test(value !== null && value !== void 0 ? value : '') || this.createError({
                        message: i18n.global.t('INVALID_DNI'),
                    });
                }
                if (identityDocType.value === 2) {
                    return /^[XYZxyz][0-9]{7}[A-Za-z]$/.test(value !== null && value !== void 0 ? value : '') || this.createError({
                        message: i18n.global.t('INVALID_NIE'),
                    });
                }
            }
            return true;
        })
            .required(function () { return i18n.global.t('FIELD_REQUIRED'); })), errorIdentityDocNum = _h.errorMessage, identityDocNum = _h.value;
        var _j = useField('province', yup.number().required(function () { return i18n.global.t('FIELD_REQUIRED'); })), errorProvince = _j.errorMessage, province = _j.value;
        var matchesValue, minValue, maxValue;
        switch (authStore.state.userData.residenceCountry.elementId) {
            case 0:
                matchesValue = /^[0-9]+$/;
                minValue = 5;
                maxValue = 5;
                break;
            case 1: // Portugal
                matchesValue = /^[0-9]{4}-[0-9]{3}$/; // Código postal portugués
                minValue = 8;
                maxValue = 8;
                break;
            default:
                matchesValue = /^\d{4}-\d{3}$/;
                minValue = 8;
                maxValue = 8;
                break;
        }
        var _k = useField('postalCode', yup
            .string()
            .required(function () { return i18n.global.t('FIELD_REQUIRED'); })
            .matches(matchesValue, function () { return i18n.global.t('WRONG_POSTALCODE_FORMAT'); })
            .min(minValue, function () { return i18n.global.t('WRONG_POSTALCODE_FORMAT'); })
            .max(maxValue, function () { return i18n.global.t('WRONG_POSTALCODE_FORMAT'); })), errorPostalCode = _k.errorMessage, postalCode = _k.value;
        var _l = useField('address', yup.string().nullable().required(function () { return i18n.global.t('FIELD_REQUIRED'); })), errorAddress = _l.errorMessage, address = _l.value;
        var _m = useField('locality', yup.string().nullable().required(function () { return i18n.global.t('FIELD_REQUIRED'); })), errorLocality = _m.errorMessage, locality = _m.value;
        var validateIdentityDocNum = function () {
            validateField('identityDocNum');
        };
        // Watch for changes in identityDocType and re-validate identityDocNum
        watch(identityDocType, function () {
            validateIdentityDocNum();
        });
        var formAddressValid = computed(function () {
            return Number(province.value) >= 0 &&
                !!postalCode.value &&
                !!locality.value &&
                !errorProvince.value &&
                !errorPostalCode.value &&
                !errorAddress.value &&
                !errorLocality.value;
        });
        var formPersonalValid = computed(function () {
            return !!alias.value &&
                !!name.value &&
                !!surname1.value &&
                !!surname2.value &&
                !!gender.value &&
                !!date.value &&
                !errorAlias.value &&
                !errorName.value &&
                !errorSurname1.value &&
                !errorSurname2.value &&
                !errorGender.value &&
                identityDocType.value >= 0 &&
                !!identityDocNum.value &&
                !errorIdentityDocType.value &&
                !errorIdentityDocNum.value &&
                !errorDate.value;
        });
        onBeforeMount(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            var _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        identityDocType.value = 0;
                        return [4 /*yield*/, lovStore.actions.getProvinces(null, { country: authStore.state.userData.residenceCountry.secondaryValue })];
                    case 1:
                        _f.sent();
                        _a = identityDocTypes;
                        return [4 /*yield*/, lovStore.actions.getIdentityDocTypes(null, { country: authStore.state.userData.residenceCountry.secondaryValue })];
                    case 2:
                        _a.value = _f.sent();
                        if (authStore.state.userData.residenceCountry.elementId === 0)
                            identityDocTypes.value = identityDocTypes.value.filter(function (doc) { return doc.id !== 1; });
                        if (authStore.state.userData.residenceCountry.elementId === 1)
                            identityDocTypes.value = identityDocTypes.value.filter(function (doc) { return doc.id === 0; });
                        alias.value = authStore.state.userData.nickname;
                        name.value = authStore.state.userData.name;
                        surname1.value = authStore.state.userData.surname1;
                        surname2.value = authStore.state.userData.surname2;
                        gender.value = authStore.state.userData.gender;
                        date.value = moment(authStore.state.userData.bornDate).format('YYYY-MM-DD');
                        province.value = (_b = authStore.state.userData.province) === null || _b === void 0 ? void 0 : _b.id;
                        postalCode.value = (_c = authStore.state.userData.postalCode) !== null && _c !== void 0 ? _c : '';
                        address.value = (_d = authStore.state.userData.address) !== null && _d !== void 0 ? _d : '';
                        locality.value = (_e = authStore.state.userData.locality) !== null && _e !== void 0 ? _e : '';
                        identityDocNum.value = authStore.state.userData.identityDocNum;
                        if (authStore.state.userData.identityDocType) {
                            identityDocType.value = lovStore.state.lov.identityDocTypes.find(function (i) { return i.id == authStore.state.userData.identityDocType.id; }).id;
                        }
                        return [2 /*return*/];
                }
            });
        }); });
        var updateImagePath = function (value) { return __awaiter(_this, void 0, void 0, function () {
            var data, success;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isLoading.value = true;
                        data = value;
                        return [4 /*yield*/, configurationStore.updateProfileImage(data)];
                    case 1:
                        success = _a.sent();
                        if (!success) {
                            isLoading.value = false;
                            Swal.fire({
                                icon: 'error',
                                title: i18n.global.t('Error_Try_Again'),
                            });
                        }
                        return [4 /*yield*/, authStore.getUserDetails()];
                    case 2:
                        _a.sent();
                        isLoading.value = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var updateGender = function (value) {
            gender.value = value;
        };
        var updatePersonalData = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, success, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!formPersonalValid.value) {
                            return [2 /*return*/];
                        }
                        data = {
                            nickname: alias.value,
                            name: name.value,
                            surname1: surname1.value,
                            surname2: surname2.value,
                            gender: gender.value,
                            bornDate: date.value.replaceAll('-', '/'),
                            identityDocType: {
                                elementId: identityDocType.value
                            },
                            identityDocNum: identityDocNum.value,
                            residenceCountry: authStore.state.userData.residenceCountry
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        isLoading.value = true;
                        return [4 /*yield*/, configurationStore.updatePersonalData(data)];
                    case 2:
                        success = _a.sent();
                        if (!success) {
                            isLoading.value = false;
                            return [2 /*return*/, Swal.fire({
                                    icon: 'error',
                                    title: setErrorMessage(configurationStore.state.details.personalDataForm.errorMessage)
                                })];
                        }
                        return [4 /*yield*/, authStore.getUserDetails()];
                    case 3:
                        _a.sent();
                        isLoading.value = false;
                        Swal.fire({
                            icon: 'success',
                            title: 'Hecho ;)',
                            text: i18n.global.t('tester_profile_updated'),
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        console.error('Error updating personal data:', error_1);
                        isLoading.value = false;
                        Swal.fire({
                            icon: 'error',
                            title: i18n.global.t('ERROR_TRY_AGAIN'),
                        });
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        var updateAddressData = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, success, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!formAddressValid.value) {
                            return [2 /*return*/];
                        }
                        data = {
                            address: address.value,
                            postalCode: postalCode.value,
                            locality: locality.value,
                            province: { elementId: province.value },
                            // identityDocType: { elementId: identityDocType.value },
                            // identityDocNum: identityDocNum.value,
                            residenceCountry: authStore.state.userData.residenceCountry
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        isLoading.value = true;
                        return [4 /*yield*/, configurationStore.updateAddressData(data)];
                    case 2:
                        success = _a.sent();
                        if (!success) {
                            isLoading.value = false;
                            return [2 /*return*/, Swal.fire({
                                    icon: 'error',
                                    title: setErrorMessage(configurationStore.state.details.addressDataForm.errorMessage)
                                })];
                        }
                        return [4 /*yield*/, authStore.getUserDetails()];
                    case 3:
                        _a.sent();
                        isLoading.value = false;
                        Swal.fire({
                            icon: 'success',
                            title: 'Hecho ;)',
                            text: i18n.global.t('tester_profile_updated'),
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        error_2 = _a.sent();
                        console.error('Error updating address data:', error_2);
                        isLoading.value = false;
                        Swal.fire({
                            icon: 'error',
                            title: i18n.global.t('ERROR_TRY_AGAIN'),
                        });
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        var setErrorMessage = function (errorCode) {
            switch (errorCode) {
                case 'dni_is_already_in_use':
                    return i18n.global.t('tester_personal_data_dni_error');
                case 'nickname_is_already_in_use':
                    return i18n.global.t('tester_personal_data_nickname_error');
                case 'email_is_already_in_use':
                    return i18n.global.t('tester_personal_data_email_error');
                case 'cif_is_not_valid':
                    return i18n.global.t('tester_personal_data_nif_error');
                case 'paypal_is_not_valid':
                    return i18n.global.t('tester_personal_data_paypal_error');
                case 'default_error_message':
                default:
                    return i18n.global.t('tester_personal_data_default_error');
            }
        };
        return {
            alias: alias,
            errorAlias: errorAlias,
            name: name,
            errorName: errorName,
            surname1: surname1,
            errorSurname1: errorSurname1,
            surname2: surname2,
            errorSurname2: errorSurname2,
            date: date,
            errorDate: errorDate,
            identityDocNum: identityDocNum,
            identityDocType: identityDocType,
            errorIdentityDocNum: errorIdentityDocNum,
            errorIdentityDocType: errorIdentityDocType,
            province: province,
            errorProvince: errorProvince,
            gender: gender,
            errorGender: errorGender,
            postalCode: postalCode,
            errorPostalCode: errorPostalCode,
            address: address,
            errorAddress: errorAddress,
            locality: locality,
            errorLocality: errorLocality,
            updateImagePath: updateImagePath,
            formAddressValid: formAddressValid,
            formPersonalValid: formPersonalValid,
            lovStore: lovStore,
            updateGender: updateGender,
            userData: userData,
            updatePersonalData: updatePersonalData,
            updateAddressData: updateAddressData,
            setErrorMessage: setErrorMessage,
            validateIdentityDocNum: validateIdentityDocNum,
            identityDocTypes: identityDocTypes,
            selectedIdentityDocType: selectedIdentityDocType,
            docTypeConfig: docTypeConfig,
            identityDocInputAttributes: identityDocInputAttributes,
            isLoading: isLoading
        };
    },
});

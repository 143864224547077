import { computed, defineComponent, inject } from 'vue';
import UploadImage from '@/components/UploadImage.vue';
export default defineComponent({
    emits: ['imagePath'],
    components: { UploadImage: UploadImage },
    setup: function (props, _a) {
        var emit = _a.emit;
        var authStore = inject('authState');
        var updateImagePath = function (file) {
            emit('imagePath', file);
        };
        return {
            userData: computed(function () { return authStore.state.userData; }),
            updateImagePath: updateImagePath,
        };
    },
});
